"use client";

import { Address } from "@medusajs/medusa";
import { useCart } from "medusa-react";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import React, { useEffect, useState } from "react";

import Breadcrumb from "@/components/Breadcrumb";
import HeaderNotice from "@/components/HeaderNotice";
import { ArrowDropRightIcon } from "@/components/Icon/Arrow/ArrowDrop/ArrowDropRightIcon";
import { Cart } from "@/components/pages/checkout/Cart";
import { HeaderNoticePosition } from "@/data/strapi";
import { useAccount } from "@/lib/contexts/account-context";
import { useLoadingRouter } from "@/lib/contexts/loading-context";
import { checkoutStepEvent, CheckoutStepEventInterface } from "@/lib/util/gtm";

interface LayoutInterface {
  children: React.ReactNode;
}

export const checkoutBreadCrumbs = [
  { name: "Anmelden", link: "/checkout", step: 0 },
  { name: "Lieferung", link: "/checkout/delivery", step: 1 },
  { name: "Zahlung", link: "/checkout/payment", step: 2 },
  { name: "Bestellübersicht", link: "/checkout/overview", step: 3, readonly: true },
  { name: "Bestellübersicht", link: "/checkout/confirm", step: 3, hidden: true, readonly: true },
  { name: "Bestellübersicht", link: "/checkout/review", step: 3, hidden: true, readonly: true },
];

export const Layout = ({ children }: LayoutInterface) => {
  const router = useLoadingRouter();
  const pathName = usePathname();
  const { cart } = useCart();
  const [isError, setError] = useState<string | null>(null);
  const [index, setIndex] = useState<number>(0);
  const [readonly, setReadonly] = useState<boolean>(false);
  const [sendGtm, setSendGtm] = useState<number | null>(null);
  useValidation(index, setError);

  useEffect(() => {
    const breadCrumb = checkoutBreadCrumbs.find((item) => item.link === pathName);

    if (breadCrumb) {
      setIndex(breadCrumb.step);
      setReadonly(breadCrumb.readonly ?? false);
    }
  }, [pathName]);

  useEffect(() => {
    if (cart && cart.id && sendGtm !== index) {
      setSendGtm(index);
      checkoutStepEvent({ step: index + 1, cart } as CheckoutStepEventInterface);
    }
  }, [cart, index, sendGtm]);

  return (
    <div className="max-w-[1620px] mx-auto">
      <div className="grid grid-cols-12 min-h-screen">
        <div className="col-span-12 lg:col-span-6">
          <div className="col-span-12 lg:col-span-6 w-11/12 lg:w-9/12 mx-auto">
            <div className="bg-white">
              <Link href="/#cart">
                <Image src="/img/channel21_logo.svg" className={`w-72 m-auto mb-10 mt-24`} alt="CHANNEL21" width={1200} height={40} priority={true} />
              </Link>

              <HeaderNotice position={HeaderNoticePosition.Before} />
              <div>
                <Breadcrumb
                  items={checkoutBreadCrumbs.filter((item) => !item.hidden)}
                  onClick={(item) => router.push(item.link as string)}
                  centered={true}
                  index={index}
                  arrow={<ArrowDropRightIcon fill="#db2777" className="w-7 h-7" />}
                />
              </div>
              <HeaderNotice position={HeaderNoticePosition.After} />

              {isError && (
                <div className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50" role="alert">
                  {isError}
                </div>
              )}

              {!isError && children}

              <div className={`mt-24 mb-10`}>
                <div className="grid grid-cols-12 w-full md:w-2/3 2xl:w-1/2 m-auto text-center">
                  <div className="col-span-4">
                    <Link href="/impressum">Impressum</Link>
                  </div>
                  <div className="col-span-4">
                    <Link href="/agb">AGB</Link>
                  </div>
                  <div className="col-span-4">
                    <Link href="/datenschutz">Datenschutz</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-12 lg:col-span-6 hidden lg:block bg-gray-100">
          <Cart showPaymentMethods={true} readonly={readonly} />
        </div>
      </div>
    </div>
  );
};

const CART_EMPTY_ERROR = "Der Warenkorb ist leer.";
const LOGIN_ERROR = "Bitte logge dich ein um mit dem Checkout-Prozess fortfahren zu können.";
const SHIPPING_ADDRESS_ERROR = "Bitte gib eine Lieferadresse an.";
const BILLING_ADDRESS_ERROR = "Es konnte keine Rechnungsadresse gefunden werden.";
const PAYMENT_ERROR = "Bitte wähle eine Zahlungsmethode aus.";

const useValidation = (index: number, setError: React.Dispatch<React.SetStateAction<string | null>>) => {
  const { customer, retrievingCustomer: isLoading } = useAccount();
  const { cart } = useCart();

  useEffect(() => {
    if (isLoading || !cart?.id) {
      return;
    }
    setError(null);

    const loginValidation = () => {
      if (customer || cart?.email) {
        return;
      }

      setError(LOGIN_ERROR);
    };

    const cartNotEmptyValidation = () => {
      if ((cart?.items || []).length > 0) {
        return;
      }

      setError(CART_EMPTY_ERROR);
    };

    const shippingAddressValidation = () => {
      const isValidShippingAddress = (input: Address | null | undefined) => {
        return input?.first_name && input?.last_name && input?.address_1 && input?.city && input?.postal_code;
      };

      if (isValidShippingAddress(cart?.shipping_address)) {
        return;
      }

      setError(SHIPPING_ADDRESS_ERROR);
    };

    const overviewValidation = () => {
      if (cart.shipping_address && cart?.billing_address && cart.payment_session) {
        return;
      }

      if (!cart.payment_session || !cart.billing_address) {
        if (!cart.billing_address) {
          setError(BILLING_ADDRESS_ERROR);
        }

        if (!cart.payment_session) {
          setError(PAYMENT_ERROR);
        }

        return;
      }

      if (!cart.shipping_address) {
        setError(SHIPPING_ADDRESS_ERROR);
      }
    };

    cartNotEmptyValidation();
    if (index >= 1) {
      loginValidation();
    }
    if (index > 1) {
      shippingAddressValidation();
    }
    if (cart?.items.length) {
      if (index === 3) {
        overviewValidation();
      }
    }
  }, [index, cart, isLoading, customer, setError]);
};
