"use client";

import { Cart as CartType, Region } from "@medusajs/medusa";
import { useCart } from "medusa-react";
import Image from "next/image";
import React, { useEffect } from "react";

import Breadcrumb from "@/components/Breadcrumb";
import { ArrowDropRightIcon } from "@/components/Icon/Arrow/ArrowDrop/ArrowDropRightIcon";
import { CartIcon } from "@/components/Icon/CartIcon";
import { PaymentProvider } from "@/components/Provider/PaymentProvider";
import { useLoading } from "@/lib/contexts/loading-context";
import { useProductPriceFormat } from "@/lib/hooks/use-product-price";
import { checkoutBreadCrumbs } from "@/modules/checkout/layout";

import { CartTotals, Products } from "./CartComponents";
import { PaybackCartType } from "./CartPayback";

interface Props {
  showPaymentMethods?: boolean;
  noTitle?: boolean;
  readonly?: boolean;
}

export const Cart = ({ showPaymentMethods, noTitle, readonly }: Props) => {
  const { cart } = useCart();
  const price = useProductPriceFormat({ region: cart?.region as Region });
  const [isLoading, setLoading] = useLoading("checkout/cart", true, 0.2);

  useEffect(() => {
    if (cart?.id) {
      setLoading(false);
    }
  }, [setLoading, cart?.id]);

  return (
    <>
      <Image src="/img/channel21_logo.svg" className={`w-72 m-auto mb-10 mt-24 invisible ${noTitle && "hidden"}`} alt="CHANNEL21" width={1200} height={40} />

      <div className={`invisible ${noTitle && "hidden"}`}>
        <Breadcrumb items={checkoutBreadCrumbs} centered={true} arrow={<ArrowDropRightIcon fill="#db2777" className="w-7 h-7" />} />
      </div>

      <div className="w-10/12 lg:w-8/12 m-auto">
        <div className={`flex justify-center align-middle ${noTitle && "hidden"}`}>
          <CartIcon width={"1.5rem"} height={"auto"} className="mr-5" />
          <div className={`text-center text-4xl uppercase font-light`}>Warenkorb</div>
        </div>

        <hr className={`mt-7 mb-14 h-0.5 border-t-0 bg-black opacity-100 ${noTitle && "hidden"}`} />

        <Products isLoading={isLoading} cart={cart as CartType} price={price} setLoading={setLoading} readonly={readonly} />

        <CartTotals cart={cart as CartType} readonly={readonly} onlySubtotal={!cart?.shipping_address} />

        {(cart as PaybackCartType)?.bonus_points_total ? (
          <div
            className={"mt-2 font-bold text-lg"}
            title={!cart?.metadata?.bonus_card ? "Geben Sie im Warenkorb ihre PAYBACK-Kartennummer ein um PAYBACK-Punkte zu erhalten" : undefined}
          >
            <div className="text-blue-700 font-extrabold flex justify-between">
              <p className="text-lg">{!cart?.metadata?.bonus_card && "Mögliche "}Payback Punkte</p>
              <p>{(cart as PaybackCartType)?.bonus_points_total}</p>
            </div>
          </div>
        ) : null}

        <div className={`mt-16 w-full lg:w-10/12 m-auto ${!showPaymentMethods && "hidden"}`}>
          <PaymentProvider />
        </div>
      </div>
    </>
  );
};
