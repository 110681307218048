import React from "react";
import Skeleton from "react-loading-skeleton";

type OverviewProps = {
  children: React.ReactNode;
};

export const PaymentListSkeleton = () => {
  return (
    <>
      <Skeleton count={1} height="2rem" width={"100%"} style={{ marginBottom: "2rem" }} />
      <Skeleton count={5} height="1.25rem" width={"100%"} style={{ marginBottom: ".5rem" }} />
    </>
  );
};

export const PaymentOverviewSkeleton = () => {
  return <Skeleton count={1} height="2rem" width={"10rem"} style={{ marginBottom: "2rem" }} />;
};

const PaymentOverViewCartLines = ({
  children,
}: React.PropsWithChildren<OverviewProps> & { Subtotal: React.FC; Shipment: React.FC; Total: React.FC; TaxTotal: React.FC }) => {
  return <>{children}</>;
};

const Subtotal: React.FC = () => {
  return (
    <div className="flex justify-between">
      <div>
        <Skeleton count={1} height="1.5rem" width="10rem" />
      </div>
      <div>
        <Skeleton count={1} height="1.5rem" width={"5rem"} />
      </div>
    </div>
  );
};

const Shipment: React.FC = () => {
  return (
    <div className="flex justify-between">
      <div>
        <Skeleton count={1} height="1.5rem" width="7.5rem" />
      </div>
      <div>
        <Skeleton count={1} height="1.5rem" width={"3.5rem"} />
      </div>
    </div>
  );
};

const Total: React.FC = () => {
  return (
    <div className="flex justify-between">
      <div>
        <Skeleton count={1} height="1.75rem" width="8.5rem" />
      </div>
      <div>
        <Skeleton count={1} height="1.75rem" width={"6rem"} />
      </div>
    </div>
  );
};

const TaxTotal: React.FC = () => {
  return (
    <div className="flex justify-between">
      <div>
        <Skeleton count={1} height="1rem" width="9rem" />
      </div>
      <div>
        <Skeleton count={1} height="1rem" width={"4rem"} />
      </div>
    </div>
  );
};

PaymentOverViewCartLines.Subtotal = Subtotal;
PaymentOverViewCartLines.Shipment = Shipment;
PaymentOverViewCartLines.Total = Total;
PaymentOverViewCartLines.TaxTotal = TaxTotal;

export default PaymentOverViewCartLines;
