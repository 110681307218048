"use client";

import { Cart, LineItem } from "@medusajs/medusa";
import { Cart as CartType } from "@medusajs/medusa/dist/models/cart";
import { useMutation } from "@tanstack/react-query";
import clsx from "clsx";
import { useCart, useProducts } from "medusa-react";
import React, { useCallback } from "react";

import { medusaClient } from "@/lib/config";
import { useProductPriceFormat } from "@/lib/hooks/use-product-price";
import { CartSale } from "@/modules/checkout/components/Discount/cart-sale";

import { CloseLineIcon } from "../../Icon/Close/CloseLineIcon";
import PaymentOverViewCartLines from "../../Skeleton/Checkout/PaymentListSkeleton";
import { CheckoutProductCardSkeleton } from "../../Skeleton/Product/Card";
import { CartProduct } from "./CartProduct";

export interface ProductsInterface {
  isLoading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  cart: CartType;
  sale?: number;
  price: {
    output: (amount: number) => string;
  };
  readonly?: boolean;
  onNavigate?: () => void;
}
interface PromoCampaignInterface {
  CouponCode: string;
  Value: {
    Value: string;
    Currency: string;
  };
}

const getPromoValue = (promoCampaigns: PromoCampaignInterface[], code: string) => {
  const PromoCode = promoCampaigns?.find((promo: { CouponCode: string }) => {
    return promo.CouponCode === code;
  });

  return (PromoCode?.Value?.Value as unknown as number) * 100;
};

export const Products = ({ isLoading, cart, price, setLoading, readonly, onNavigate }: ProductsInterface) => {
  const { products } = useProducts(
    {
      id: cart?.items?.map((_product: LineItem) => _product?.variant?.product_id),
      cart_id: cart?.id,
    },
    {
      enabled: !!cart?.id && !!cart?.region_id && cart?.items?.length > 0,
    },
  );

  if (isLoading) {
    return <CheckoutProductCardSkeleton />;
  }

  if (!cart?.items?.length) {
    return <p className="text-xl text-center">Keine Produkte im Warenkorb</p>;
  }

  return (
    <>
      {cart?.items?.map((lineItem: LineItem) => {
        const product = products?.filter((p) => p.id === lineItem.variant.product_id)?.[0];
        const variant = product?.variants?.filter((_variant) => _variant.id === lineItem.variant.id)?.[0];
        if (!product || !variant) {
          return null;
        }

        return (
          <CartProduct
            key={lineItem.id}
            price={price}
            lineItem={lineItem}
            cartId={cart?.id ?? ""}
            setLoading={setLoading}
            readonly={readonly}
            pricedProduct={product}
            variant={variant}
            handle={products?.filter((p) => p.id === lineItem.variant.product_id)?.[0].handle}
            onNavigate={onNavigate}
          />
        );
      })}
    </>
  );
};

interface CartTotalsProps {
  cart: Cart;
  readonly?: boolean;
  isDetailPage?: boolean;
  onlySubtotal?: boolean;
}

export const CartTotals = ({ cart, readonly, isDetailPage = false, onlySubtotal = false }: CartTotalsProps) => {
  const { setCart } = useCart();
  const price = useProductPriceFormat({ region: cart?.region });
  const { mutate: removeDiscount } = useMutation((payload: { cartId: string; code: string }) => {
    return medusaClient.carts.deleteDiscount(payload.cartId, payload.code);
  });
  const onRemove = useCallback(
    (code: string) => {
      removeDiscount(
        { cartId: cart?.id, code: code },
        {
          onSuccess: ({ cart: _cart }) => {
            setCart(_cart);
          },
        },
      );
    },
    [cart?.id, removeDiscount, setCart],
  );

  if (!cart.items.length && !cart.total) {
    return (
      <div className="mt-10 mb-5">
        <hr className="h-0.5 border-t-0 bg-black opacity-100" />
      </div>
    );
  }

  const shipping_amount = (cart?.shipping_total || 0) + (cart?.shipping_tax_total || 0);
  let subtotal = (cart?.total || 0) - shipping_amount;
  (cart?.metadata?.promoDiscountCodes as string | undefined)?.split(",").forEach((code) => {
    subtotal -= getPromoValue(cart?.metadata?.promoCampaigns as PromoCampaignInterface[], code) || 0;
  });

  return (
    <div className={clsx({ "mb-20 font-semibold": isDetailPage }, { "mt-16 font-bold text-lg": !isDetailPage })}>
      {(cart?.subtotal as unknown as number) >= 0 ? (
        <div className="flex justify-between">
          <p>Zwischensumme</p>
          <p>{price.output(subtotal)}</p>
        </div>
      ) : (
        <PaymentOverViewCartLines.Subtotal />
      )}

      {onlySubtotal && <div className="font-normal text-sm">Die Versandkosten werden nach der Adresseingabe berechnet.</div>}

      {!onlySubtotal &&
        ((cart?.shipping_total as unknown as number) >= 0 ? (
          <div className="mt-2 flex justify-between">
            <p>Versand</p>
            <p>{price.output(shipping_amount)}</p>
          </div>
        ) : (
          <PaymentOverViewCartLines.Shipment />
        ))}

      {!onlySubtotal && cart?.metadata?.promoDiscountCodes
        ? (cart?.metadata?.promoDiscountCodes as string).split(",")?.map((code: string) => {
            const promoValue = getPromoValue(cart?.metadata?.promoCampaigns as PromoCampaignInterface[], code);
            return (
              <div className="mt-2 flex justify-between" key={`promo_${code}`}>
                <div className="flex flex-row items-center">
                  {!readonly && <CloseLineIcon className="w-5 h-5 mr-1 cursor-pointer" fill="#db2777" onClick={() => onRemove(code)} />}
                  <p>Code {code}</p>
                </div>
                <p>{promoValue ? price.output(promoValue) : null}</p>
              </div>
            );
          })
        : null}

      <div className="mt-5 mb-5">
        <hr className={clsx({ "border-t-0 bg-black": true }, { "h-[1px]": isDetailPage }, { "h-0.5": !isDetailPage })} />
      </div>

      {!onlySubtotal &&
        ((cart?.total as unknown as number) >= 0 ? (
          <div className={clsx({ "flex justify-between font-extrabold": true }, { "text-lg mb-2": isDetailPage }, { "text-2xl": !isDetailPage })}>
            <p>Gesamt</p>
            <p>{price.output(cart?.total)}</p>
          </div>
        ) : (
          <PaymentOverViewCartLines.Total />
        ))}

      {!onlySubtotal &&
        ((cart?.tax_total as unknown as number) >= 0 ? (
          <div className="mt-2 flex justify-between">
            <p className="text-sm">Enthaltene MwSt.</p>
            <p className="text-sm">{price.output(cart?.tax_total)}</p>
          </div>
        ) : (
          <PaymentOverViewCartLines.TaxTotal />
        ))}

      <CartSale cart={cart} />
    </div>
  );
};
