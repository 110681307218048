import React from "react";

import { AmazonPayIcon } from "@/components/Icon/Provider/AmazonPayIcon";
import { KlarnaIcon } from "@/components/Icon/Provider/KlarnaIcon";
import { MastercardIcon } from "@/components/Icon/Provider/MastercardIcon";
import { PayPalIcon } from "@/components/Icon/Provider/PayPalIcon";
import { SepaIcon } from "@/components/Icon/Provider/SepaIcon";
import { SofortUeberweisungIcon } from "@/components/Icon/Provider/SofortUeberweisungIcon";
import { VisaIcon } from "@/components/Icon/Provider/VisaIcon";
import { ZiniaIcon } from "@/components/Icon/Provider/ZiniaIcon";

export const PaymentProvider = () => (
  <>
    <p className="uppercase font-bold mb-6">Ihre Zahlungsmöglichkeiten</p>

    <div className="flex justify-between">
      <p className="font-bold">Kreditkarte</p>
      <div className="flex gap-x-2">
        <MastercardIcon className="w-10 h-10" />
        <VisaIcon className="w-10 h-10" />
      </div>
    </div>

    <div className="flex justify-between">
      <p className="font-bold">PayPal</p>
      <div className="flex gap-x-2">
        <PayPalIcon className="w-10 h-10" />
      </div>
    </div>

    <div className="flex justify-between">
      <p className="font-bold">Amazon Payments</p>
      <div className="flex gap-x-2">
        <AmazonPayIcon className="w-10 h-10" />
      </div>
    </div>

    <div className="flex justify-between">
      <p className="font-bold">Sofortüberweisung</p>
      <div className="flex gap-x-2">
        <SofortUeberweisungIcon className="w-10 h-10" />
      </div>
    </div>

    <div className="flex justify-between">
      <p className="font-bold">Bankeinzug</p>
      <div className="flex gap-x-2">
        <SepaIcon className="w-10 h-10" />
      </div>
    </div>

    <div className="flex justify-between">
      <p className="font-bold">Rechnungskauf*</p>
      <div className="flex gap-x-2">
        <KlarnaIcon className="w-10 h-10" />
        <ZiniaIcon className="w-10 h-10" />
      </div>
    </div>

    <div className="flex justify-between">
      <p className="font-bold">Ratenkauf*</p>
      <div className="flex gap-x-2">
        <ZiniaIcon className="w-10 h-10" />
      </div>
    </div>

    <small className="text-gray-700">*Bonität vorausgesetzt</small>
  </>
);
