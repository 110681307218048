"use client";

import { LineItem, ProductVariant } from "@medusajs/medusa";
import { PricedProduct, PricedVariant } from "@medusajs/medusa/dist/types/pricing";
import { useCart, useUpdateLineItem } from "medusa-react";
import Link from "next/link";
import React, { useState } from "react";

import { LineItemRemoveAction } from "@/components/Product/action/lineitem-remove-action";
import { PlaceholderImageString, ProductImage } from "@/components/Product/ProductImage";
import { CheckoutProductCardSkeleton } from "@/components/Skeleton/Product/Card";
import medusaRequest from "@/data/medusa-fetch";
import { useBasePrice } from "@/hook/product";
import { getPercentageDiff } from "@/lib/util/get-precentage-diff";
import { addToCartEvent, removeFromCartEvent } from "@/lib/util/gtm";

export interface CartProps {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  cartId: string;
  price: { output: (amount: number) => string };
  readonly?: boolean;
  handle?: null | string;
}

interface VariantWithImage extends ProductVariant {
  thumbnail: string;
}

export const CartProduct = ({
  lineItem,
  price,
  cartId,
  setLoading,
  readonly,
  handle,
  pricedProduct,
  variant,
  onNavigate,
}: CartProps & { lineItem: LineItem; pricedProduct: PricedProduct; variant: PricedVariant; onNavigate?: () => void }) => {
  const { mutateAsync } = useUpdateLineItem(cartId);
  const { cart, setCart } = useCart();
  const getBasePrice = useBasePrice();
  const [error, setError] = useState<string | null>(null);
  const [isLoading, _setLoading] = useState<boolean>(false);

  if (isLoading || !cart) {
    return <CheckoutProductCardSkeleton />;
  }

  const { metadata } = lineItem.variant;
  const imageSrc = (lineItem.variant as VariantWithImage).thumbnail ?? lineItem.thumbnail;

  const mutateQuantity = (quantity: number) => {
    if (quantity >= 11) {
      setError("Maximale Bestellmenge 10");
      return;
    }

    if (quantity === 0) {
      if (!confirm("Wollen Sie den Artikel wirklich löschen?")) {
        return;
      }
    }

    _setLoading(true);

    const mutate = mutateAsync(
      { lineId: lineItem.id, quantity: quantity },
      {
        async onSuccess(data) {
          if (data.cart.metadata?.shippingCostUpdateRequired) {
            const cartValidation = await medusaRequest("POST", `/carts/${data.cart.id}/validate-shipping`);
            if (cartValidation.ok) {
              setCart(cartValidation.body.cart);
            } else {
              setError("Die Versandkosten konnten nicht berechnet werden");
            }
          } else {
            setCart(data.cart);
          }

          setError(null);

          if (quantity > lineItem.quantity) {
            addToCartEvent({ product: pricedProduct, variant, region: cart.region });
          }
          if (quantity < lineItem.quantity) {
            removeFromCartEvent({ product: pricedProduct, variant, region: cart.region });
          }
        },
        onError() {
          setError("Die Artikelmenge ist leider nicht mehr verfügbar");
        },
      },
    );

    mutate.finally(() => _setLoading(false));
  };

  let discountTotal = lineItem.discount_total!;
  let basePrice = lineItem.unit_price!;
  const quantity = lineItem.quantity!;

  if (metadata?.original_price) {
    const originalPrice = Number(metadata.original_price) * 100;
    if (!isNaN(originalPrice) && originalPrice > basePrice) {
      discountTotal += originalPrice - basePrice;
      basePrice = originalPrice;
    }
  }

  return (
    <div className="grid grid-cols-12 mb-8">
      <div className="col-span-3 lg:col-span-2">
        <Link href={`/p/${handle}`} onClick={onNavigate}>
          <ProductImage src={imageSrc} alt={lineItem.title} fallbackimage={PlaceholderImageString} width={128} height={192} />
        </Link>
      </div>
      <div className="col-span-9 lg:col-span-10 pl-7">
        <div className="font-bold mb-3 text-sm">{lineItem.title}</div>

        {metadata?.filter_color ? <p className="text-sm">Farbe: {metadata?.filter_color as string}</p> : null}
        {metadata?.filter_size ? <p className="text-sm">Größe: {metadata?.filter_size as string}</p> : null}

        <div className="flex justify-between">
          <p className="mb-2 text-sm">
            Anzahl:
            {!readonly && (
              <span className="pl-2 cursor-pointer" onClick={() => mutateQuantity(lineItem.quantity - 1)}>
                -
              </span>
            )}
            <span className="px-2">{lineItem.quantity}</span>
            {!readonly && (
              <span className="cursor-pointer" onClick={() => mutateQuantity(lineItem.quantity + 1)}>
                +
              </span>
            )}
          </p>
          {!readonly && <LineItemRemoveAction cartId={cartId} lineItem={lineItem} setLoading={setLoading} pricedProduct={pricedProduct} variant={variant} />}
        </div>

        <p className={`${discountTotal > 0 && "text-pink-600"} font-black`}>
          {price.output(lineItem.total!)}
          {discountTotal > 0 && (
            <>
              <s className="text-black text-sm ml-3 font-normal">{price.output(basePrice * lineItem.quantity)}</s>
              <span className="ml-3 font-black">-{getPercentageDiff(basePrice * quantity, lineItem.total!)}%</span>
            </>
          )}
        </p>

        <p className="text-sm">
          <small>{getBasePrice(lineItem.variant, (lineItem.total || 0) / (lineItem.quantity || 1))}</small>
        </p>

        {error && <p className="text-ch21-color text-center text-sm">{error}</p>}
      </div>
    </div>
  );
};
