import { LineItem } from "@medusajs/medusa";
import { PricedProduct, PricedVariant } from "@medusajs/medusa/dist/types/pricing";
import { useCart, useDeleteLineItem } from "medusa-react";
import React from "react";

import { TrashIcon } from "@/components/Icon/Tash/TrashIcon";
import medusaRequest from "@/data/medusa-fetch";
import { removeFromCartEvent } from "@/lib/util/gtm";

export const LineItemRemoveAction = ({
  cartId,
  lineItem,
  setLoading,
  pricedProduct,
  variant,
}: {
  cartId: string;
  lineItem: LineItem;
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  pricedProduct: PricedProduct;
  variant: PricedVariant;
}) => {
  const { mutateAsync } = useDeleteLineItem(cartId);
  const cart = useCart();

  const remove = async () => {
    if (confirm("Wollen Sie den Artikel wirklich löschen?")) {
      await mutateAsync(
        {
          lineId: lineItem.id,
        },
        {
          async onSuccess(data) {
            if (data.cart.metadata?.shippingCostUpdateRequired) {
              const cartValidation = await medusaRequest("POST", `/carts/${data.cart.id}/validate-shipping`);
              if (cartValidation.ok) {
                cart.setCart(cartValidation.body.cart);
              }
            } else {
              cart.setCart(data.cart);
            }

            removeFromCartEvent({ product: pricedProduct, variant, region: data.cart.region });
          },
        },
      );

      setLoading && setLoading(false);
    }
  };

  return <TrashIcon className="w-5 h-5 cursor-pointer" onClick={() => remove()} />;
};
