import { Cart, LineItem, Region } from "@medusajs/medusa";
import React, { useMemo } from "react";

import { useProductPriceFormat } from "@/lib/hooks/use-product-price";

interface CartSaleInterface {
  cart: Omit<Cart, "refundable_amount" | "refunded_total"> | undefined;
}

export const CartSale = ({ cart }: CartSaleInterface) => {
  const price = useProductPriceFormat({ region: cart?.region as Region });

  const sale = useMemo(() => {
    let sale = 0;

    cart?.items.forEach((lineItem: LineItem) => {
      let basePrice = lineItem.unit_price;

      const metadata = lineItem.variant?.metadata;

      if (metadata?.original_price) {
        const originalPrice = Number(metadata.original_price) * 100;
        if (!isNaN(originalPrice) && originalPrice > basePrice) {
          basePrice = originalPrice;
        }
      }

      sale += basePrice * lineItem.quantity - lineItem.total!;
    });

    return sale;
  }, [cart]);

  if (sale <= 0) {
    return null;
  }

  return (
    <div className="mt-2 text-pink-600 font-extrabold flex justify-between">
      <p className="text-lg">Sie sparen</p>
      <p>{price.output(sale)}</p>
    </div>
  );
};
